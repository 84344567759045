<template>
  <div class="d-flex flex-row flex-wrap flex-grow-1 flex-shrink-1 mx-5 mt-3">
    <div
      class="d-flex flex-row flex-wrap flex-grow-1 align-content-start"
      style="flex-basis: 25%;"
    >
      <v-text-field
        background-color="#2b2e5b24"
        class="ft font-weight-medium rounded-sm primary--text text-capitalize"
        color="primary"
        flat
        placeholder="Search categories ..."
        prepend-inner-icon="search"
        solo
      />
    </div>
    <div class="d-flex flex-row flex-wrap justify-end" style="flex-basis: 25%;">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar"
};
</script>

<style scoped></style>
